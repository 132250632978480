import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Phone = () => {
  return (
    <div className="mt-20 flex flex-col sm:flex-col md:flex-row justify-center items-center bg-gray-50 rounded-md p-4 space-y-4 md:space-y-0 md:space-x-8">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faPhone} className="mr-2" beatFade />
        <span className="text-blue-900 font-semibold">+1 403-850-3304</span>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" beatFade />
        <span className="text-blue-900 font-semibold">adeze2005@gmail.com</span>
      </div>
    </div>
  );
};

export default Phone;
