import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div>
      <NavBar />
      <div class="mt-20 ">
        <h1 class="relative flex justify-center content-center text-4xl">
          Projects
        </h1>
      </div>
      <div className="flex justify-center mt-5">
        <div className="w-full max-w-screen-lg px-4">
          <ul className="flex flex-col w-full">
            <li className="mb-6">
              <Project
                title="Jglobal Logistics (CA, AB)"
                link="https://www.jgloballogistics.ca//"
                description="Website design for a start-up logistics company in Calgary Alberta. "
              />
            </li>
            <li className="mb-6">
              <Project
                title="Blissfuldayspsych (MD, USA)"
                link="https://www.blissfuldayspsych.com/"
                description="Website design and makover for a start-up healthcare practitioner in Maryland USA. "
              />
            </li>
            <li className="mb-6">
              <Project
                title="Algorithm Implementation (CA, AB)"
                link="https://github.com/prinze2005-prod/linkedlist_dataStructure.git/"
                description="Jan 2024 – Mar 2023 This console-based c++ algorithm is based on linkedlist data structure. It implements singly linkedlist and it’s operations on node(data) insertion, deletion, iterative and recursive functions and sort. Many other functions have also been added to the code base ensuring optimal performance. Feel free to clone the repo and try it out."
              />
            </li>
            <li className="mb-6">
              <Project
                title="CRUD database project (CA, AB)"
                link="https://github.com/prinze2005-prod/CRUD_project.git"
                description="Jan 2024 – Mar 2023 This project implements a CRUD application using tools such as MYSQL database, NodeJS and expressJS, React for frontend. Feel free to clone the repo and try it out."
              />
            </li>
            <li className="mb-6">
              <Project
                title="Java log-in projects | SAIT(CA, AB)"
                link="https://github.com/prinze2005-prod/loginProject.git"
                description="Sep 2022 – Jan 2023 Project was designed to show the importance of log-in security system in front-end application. It also implements the use of the CRUD(create, read, update and delete) techniques for writing and retrieving contents to and from a database. Feel free to clone the repo and try it out."
              />
            </li>
            <li className="mb-6">
              <Project
                title="Home Pro | SAIT (CA, AB)"
                link="https://github.com/prinze2005-prod/ddev-mern.git"
                description="Jan 2022 – Aug 2022 Project was born out the need to have a site which provides clients a one-stop-shop for all home services. From plumbing, to electrical repairs, providing clients with rich user experience. The baseline code is react for front-end, node, JavaScript and MongoDB for database. Feel free to clone the repo and try it out."
              />
            </li>
            <li>
              <Project
                title="Terraform AWS Project (CA, AB)"
                link="https://github.com/prinze2005-prod/AWS-terraform_scripts.git"
                description="Jan 2021 – Dec 2021 This is a terraform project to setup a dev environment in AWS including a kubernetes cluster and related dependencies for automating, scaling and management of containerized application. The baseline code is Hashicorp Terraform. Feel free to clone the repo and try it out."
              />
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Project = ({ title, link, description }) => {
  return (
    <div className="relative flex flex-col gap-2">
      <span className="absolute left-0 grid !w-[78px] justify-center bg-transparent transition-opacity duration-200">
        <span className="h-full w-0.5 bg-blue-gray-100"></span>
      </span>
      <div className="relative flex items-center gap-4 py-3 pl-4 pr-8 bg-white border shadow-lg rounded-xl border-blue-gray-50 shadow-blue-gray-900/5 hover:transform hover:scale-105 transition-transform">
        <span className="relative z-[2] w-max flex-shrink-0 overflow-hidden rounded-full bg-gray-900/10 p-3 text-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
        <div className="flex flex-col gap-3">
          <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            <a href={link} className="hover:underline">
              {title}
            </a>
          </h6>
          <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Projects;
