import React from "react";
import {
  cpp,
  devops,
  react,
  linux,
  database,
  docker,
  terraform,
  github,
  nginx,
} from "../assets";

const Skills = () => {
  return (
    <div className="mx-5 rounded-lg bg-slate-50">
      {/* Header Section */}
      <div className="mt-40 justify-center text-center">
        <p className="text-3xl">My Expertise, My Skills</p>
      </div>

      {/* Cards Section */}
      <div className="container mb-20 lg:mt-11 mx-auto grid grid-cols-1 md:grid-cols-3 gap-10  text-1xl justify-center items-center">
        {/* Card: C++ */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={cpp} className="rounded-lg" alt="C++" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            C++
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in algorithm designs that drive search engines and
            implementing such, using the concepts of Object-Oriented Programming
            (O.O.P).
          </p>
        </div>

        {/* Card: Docker */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={docker} className="rounded-full" alt="Docker" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Docker
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in Docker and its use in packaging applications, building
            custom Docker images, and leveraging Kubernetes to manage the
            lifecycle of Docker and related tools like Vault.
          </p>
        </div>

        {/* Card: DevOps */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={devops} alt="DevOps" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            DevOps
          </h2>
          <p className="leading-relaxed text-base">
            Experienced in DevOps and Cloud practices, specializing in automated
            deployment leveraging CI/CD pipelines and toolchains to streamline
            the release process.
          </p>
        </div>

        {/* Card: React */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={react} alt="React" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            React
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in the React framework, specializing in building modern
            and responsive user interfaces for web applications while ensuring
            state management.
          </p>
        </div>

        {/* Card: Database */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={database} alt="Database" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Database
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in database design concepts, mapping, normalization, and
            providing administrative support for SQL and NoSQL databases, either
            on-premise or cloud-based.
          </p>
        </div>

        {/* Card: Linux */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={linux} alt="Linux" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Linux
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in the use of all variants of Linux, easy to stand up and
            teardown, and manage the security of applications while customizing
            system behavior to manage resources effectively.
          </p>
        </div>

        {/* Card: Terraform */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={terraform} alt="Terraform" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            Terraform
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in Terraform and Terraform Cloud to deploy, manage, and
            rebuild resources with flexibility while maintaining architectural
            integrity.
          </p>
        </div>

        {/* Card: GitHub */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={github} alt="GitHub" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            GitHub
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in GitHub, GitLab, and Bitbucket as single sources of
            truth for deployment, storage, and versioning.
          </p>
        </div>

        {/* Card: LoadBalancer */}
        <div className="border border-gray-200 bg-gray-100 p-4 rounded-lg h-auto hover:shadow-lg transition-shadow duration-300">
          <div className="w-10 h-10 inline-flex items-center justify-center bg-purple-100 text-purple-500 mb-4">
            <img src={nginx} alt="LoadBalancer" />
          </div>
          <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
            LoadBalancer
          </h2>
          <p className="leading-relaxed text-base">
            Proficient in configuring custom or cloud-provided load balancers to
            ensure high availability and secure HTTPS across load balancers or
            proxy servers.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Skills;
