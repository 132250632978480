import React from "react";

const Footer = () => {
  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="bg-dark-100">
      <div className="mx-auto max-w-screen-xl p-4 py-6 lg:py-8 flex flex-col items-center justify-center">
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {getCurrentYear()}. All Rights Reserved. Evergreen-Invent
          </span>
          {/* Add your social media links here */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
