import React from "react";
import { logo, blissfuldays } from "../assets";
import { Link } from "react-router-dom";

const clientLogos = [
  {
    logo: logo,
    alt: "J Global Logistics",
    site: "https://www.jgloballogistics.ca/",
  },
  {
    logo: blissfuldays,
    alt: "Blissful Days Psych",
    site: "https://www.blissfuldayspsych.com/",
  },
];

const Partners = () => {
  return (
    <section className="ezy__clients8 light py-14 md:py-24 dark:bg-[#0b1727] text-zinc-900 dark:text-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-12 justify-center mb-16">
          <div className="col-span-12 lg:col-span-6 lg:col-start-4 text-center">
            <h2 className="font-bold text-[25px] lg:text-[35px] leading-none mb-6">
              Meet Our Clients & Partners
            </h2>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-12 lg:flex lg:flex-wrap justify-center text-center">
          {clientLogos.map((client, i) => (
            <div
              className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3"
              key={i}
            >
              <a
                href={client.site}
                target="_blank"
                rel="noopener noreferrer"
                className=" dark:bg-[#1E2735] rounded-lg h-full grid place-items-center p-4 lg:p-12"
              >
                <img
                  src={client.logo}
                  alt={client.alt}
                  className="max-h-[80px] h-auto max-w-full rounded-lg "
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
