import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Carousel from "./Carousel";
import Slider from "./Slider";
import Partner from "./Partner";
import Phone from "./Phone";
import Skills from "./Skills";
import Partners from "./Partners";

const Home = () => {
  return (
    <>
      <Phone />
      <NavBar />
      <Carousel />
      <Skills />
      <Partner />
      <Partners />
      <Footer />
    </>
  );
};

export default Home;
